// Import dependencies
import React from "react"

// Import components
import Layout from "../components/layouts/layout"
import SEO from "../components/seo/seo"
import Header from '../components/header/header'
import { TitleText } from "../components/styled/UILibrary"

// Import styles
import './styles/not-found.css'


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className='not-found__container'>
      <Header />
      <div>
        <TitleText minSize={40} maxSize={65} nolineheight center>
          404
        </TitleText>
        <TitleText minSize={18} maxSize={18} nolineheight center>
          Page not found...
        </TitleText>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
